import React, { useState } from "react";
import "../../Dashboard.css";
import { NavLink } from "react-router-dom";
import DashboardModals from "../DashboardModals";
import { useAudio } from "../../../../AudioContext";
const ProductItems = (props) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState("");
  const { playClickSound, playGeneratedSound } = useAudio();
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
  const usertoken = localStorage.getItem("token");
  const [productData, setProductData] = useState([]);

  const showModal = async (type, e) => {
    setModalType(type);
    setModalVisible(true);
    getProduct(e);
  };

  const getProduct = async (e) => {
    const response = await fetch("https://api.menuverse.ai/menu/getProduct/" + e, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
    });

    if (response.status == 200) {
      const responseJson = await response.json();

      const products = responseJson.data;

      const productOptions = products.map((product) => {
        return { value: product.id, label: product.name };
      });

      productOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductData(productOptions);

      console.log("productData", productOptions);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSaveMenu = () => {
    if (props.onSaveMenu) {
      props.onSaveMenu();
    }
  };

  return (
    <>
      <div className="col">
        <div className={`Option ${props.AcDeactive ? props.AcDeactive : ""}`}>
          <div className="Option_Logo">
            <img src={`/assets/Images/DashboardImages/${props.ProuctImage}`} alt="" />

            {props.Option_Logo && (
              <NavLink className="DollarLogo" data-bs-toggle="modal" data-bs-target="#NotAvailable" onClick={playClickSound}>
                $
              </NavLink>
            )}
            <div className="Product-Name-Price">
              <p>{props.ProductItemsTitle}</p>
              <div className="Product-Total-AMT">
                <h6>{props.ProductItemsPrice}</h6>
                <div className="AMT">
                  <p>AMT:</p>
                  <p>$1056.00 </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Option_Links">
            <span className="Coupon-Link">
              <NavLink data-bs-toggle="modal" data-bs-target="#DiscountCoupons" onClick={playClickSound}>
                Apply Coupon
              </NavLink>
            </span>
            <NavLink
              onClick={() => {
                showModal("GREATJOB", props.ProductItemsTitle);
                playClickSound();
              }}
              data-bs-toggle="modal"
              data-bs-target={"#Edit_Product" + props.ProductItemsTitle}
            >
              <span> EDIT</span>
            </NavLink>
          </div>
        </div>
        <DashboardModals isVisible={isModalVisible} type={modalType} onClose={closeModal} modalId={"Edit_Product" + props.ProductItemsTitle} productData={productData} productDetail={props.productDetail} interestData={props.interestData} termData={props.termData} second_termData={props.second_termData} onSaveMenu={handleSaveMenu} />
      </div>
    </>
  );
};

export default ProductItems;
