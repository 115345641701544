import React, { useState, useEffect } from "react";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import MyButton from "../CommonFolder/MyButton";
import Modal from "../Deals/Modal";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";
import axios from "axios";

const AddDeal = ({ setIsLogin }) => {
  let half = "mb-3 DisabledInputs";
  const usertoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicle: "",
    vinNumber: "",
    vehicleColor: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.menuverse.ai/states/show", {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        });
        setstateData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.menuverse.ai/cities/show", {
          headers: {
            Authorization: `Bearer ${usertoken}`,
          },
        });
        setcityData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    let parsedValue = value;

    // Parse the value for city and state as numbers
    if (name === "city" || name === "state") {
      parsedValue = parseInt(value, 10);
    } else if (name === "phoneNumber" || name === "zipCode") {
      parsedValue = value.replace(/\D/g, "");
    }

    setFormData({ ...formData, [name]: parsedValue });
  };

  const validateForm = () => {
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      // Only validate if the field exists in formData (i.e., it's part of the form)
      if (formData[field] !== undefined) {
        const value = formData[field];

        if (rules.required && !value) {
          newErrors[field] = rules.messages.required;
        } else if (rules.minLength && value.length < rules.minLength) {
          newErrors[field] = rules.messages.minLength;
        } else if (rules.maxLength && value.length > rules.maxLength) {
          newErrors[field] = rules.messages.maxLength;
        } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
          newErrors[field] = rules.messages.pattern;
        } else if (rules.length && value.length !== rules.length) {
          newErrors[field] = rules.messages.length;
        }
      }
    }

    // Check if select fields are selected
    if (!formData.city) newErrors.city = "Please select a city.";
    if (!formData.state) newErrors.state = "Please select a state.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("api called just");

    if (!validateForm()) {
      return;
    }
    console.log("api called and run");
    fetch("https://api.menuverse.ai/newdeal/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            localStorage.clear();
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const dealId = data.data.id;
        toast.dismiss();
        toast.success("Your deal has been added successfully!");
        navigate(`/DetailVerification/${dealId}`);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to add the deal.");
      });
  };

  return (
    <section className="Body_Section">
      <div className="container-fluid px-lg-20">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="Full_Table">
              <TableHeader title="ABC Dealer Group" showSareLink={true} />
              <form onSubmit={handleSubmit} className="needs-validation" id="Add-Deal-Form" noValidate>
                <div className="container Add_Dealer">
                  <div className="row row-cols-lg-2 row-cols-md-1">
                    {/* First Name */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="firstName" lableValue="First Name" required value={formData.firstName} onChange={handleChange} errorsMSG={errors.firstName} />
                      {errors.firstName && <p className="Add-Deal-Error-msg">{errors.firstName}</p>}
                    </div>

                    {/* Middle Name */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="middleName" lableValue="Middle Name" value={formData.middleName} onChange={handleChange} />
                    </div>

                    {/* Last Name */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="lastName" lableValue="Last Name" required value={formData.lastName} onChange={handleChange} errorsMSG={errors.lastName} />
                      {errors.lastName && <p className="Add-Deal-Error-msg">{errors.lastName}</p>}
                    </div>

                    {/* Phone Number */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="tel" InputName="phoneNumber" lableValue="Phone Number" required value={formData.phoneNumber} onChange={handleChange} errorsMSG={errors.phoneNumber} maxlength="12" minlength="10" />
                      {errors.phoneNumber && <p className="Add-Deal-Error-msg">{errors.phoneNumber}</p>}
                    </div>

                    {/* Email */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="email" InputName="email" lableValue="Email" required value={formData.email} onChange={handleChange} errorsMSG={errors.email} />
                      {errors.email && <p className="Add-Deal-Error-msg">{errors.email}</p>}
                    </div>

                    {/* Street Address */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="streetAddress" lableValue="Street Address" required value={formData.streetAddress} onChange={handleChange} errorsMSG={errors.streetAddress} />
                      {errors.streetAddress && <p className="Add-Deal-Error-msg">{errors.streetAddress}</p>}
                    </div>

                    {/* State */}
                    <div className="position-relative">
                      <SelectField
                        className={half}
                        InputName="state"
                        Selectlabel="State"
                        value={formData.state}
                        onChange={handleChange}
                        errorsMSG={errors.state}
                        options={stateData.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      />
                      {errors.state && <p className="Add-Deal-Error-msg">{errors.state}</p>}
                    </div>

                    {/* City */}
                    <div className="position-relative">
                      <SelectField
                        className={half}
                        InputName="city"
                        Selectlabel="City"
                        value={formData.city}
                        onChange={handleChange}
                        errorsMSG={errors.city}
                        options={cityData.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      />
                      {errors.city && <p className="Add-Deal-Error-msg">{errors.city}</p>}
                    </div>

                    {/* Zip Code */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="zipCode" lableValue="Zip Code" required value={formData.zipCode} onChange={handleChange} errorsMSG={errors.zipCode} maxlength="5" minlength="5" />
                      {errors.zipCode && <p className="Add-Deal-Error-msg">{errors.zipCode}</p>}
                    </div>

                    {/* Vehicle */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="vehicle" lableValue="Vehicle" value={formData.vehicle} onChange={handleChange} />
                    </div>

                    {/* VIN Number */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="vinNumber" lableValue="VIN Number" value={formData.vinNumber} onChange={handleChange} />
                    </div>

                    {/* Vehicle Color */}
                    <div className="position-relative">
                      <InputFiled InputBoxClassname={half} InputType="text" InputName="vehicleColor" lableValue="Vehicle Color" value={formData.vehicleColor} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="Table_foot justify-content-end">
                    <div className="Foot_btn">
                      <MyButton type="submit" title="Create deal" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </section>
  );
};

export default AddDeal;
