import React, { useState, useEffect, Suspense } from "react";
import "./Navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import { RxEnterFullScreen, RxExitFullScreen, RxHamburgerMenu } from "react-icons/rx";
import { useAudio } from "../../AudioContext";

const NavbarModal = React.lazy(() => import("../NavBar/NavbarModal"));

const Navbar = ({ onLogout, handle, sharedState }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [userName, setUserName] = useState("");
  const [fullScreen, setfullScreen] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [countdownActive, setCountdownActive] = useState(false);
  const [IsShared, setIsShared] = useState(false);
  const location = useLocation();
  const shouldHideSpan = location.pathname === "/AddDeal" || location.pathname === "/";
  const { playClickSound } = useAudio();

  // here is start navbar timer code ****

  useEffect(() => {
    const savedCountdown = localStorage.getItem("countdown");
    const savedElapsedTime = localStorage.getItem("elapsedTime");
    const countdownEndTime = localStorage.getItem("countdownEndTime"); // Time when the countdown is expected to end
    localStorage.setItem("selectedValueForWaiting", sharedState);

    if (savedCountdown && countdownEndTime) {
      const timeRemaining = Math.max(parseInt(countdownEndTime, 10) - Date.now(), 0);
      if (timeRemaining > 0) {
        setCountdown(Math.floor(timeRemaining / 1000)); // Set the remaining countdown in seconds
        setCountdownActive(true);
      } else {
        setCountdown(0);
        setElapsedTime(parseInt(savedElapsedTime || "0", 10));
      }
    } else if (sharedState) {
      const newCountdown = sharedState * 60;
      setCountdown(newCountdown); // Set countdown based on sharedState
      localStorage.setItem("countdownEndTime", Date.now() + newCountdown * 1000); // Store the exact end time
      setCountdownActive(true);
    }

    if (savedElapsedTime && !countdownActive) {
      setElapsedTime(parseInt(savedElapsedTime, 10));
    }
  }, [sharedState]);

  // Timer logic for countdown and elapsed time
  useEffect(() => {
    let countdownInterval;
    let elapsedInterval;

    if (countdownActive && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          localStorage.setItem("countdown", newCountdown); // Save to local storage

          if (newCountdown <= 0) {
            clearInterval(countdownInterval);
            setCountdownActive(false);
            setElapsedTime(0); // Reset elapsed time when countdown finishes
            localStorage.removeItem("countdownEndTime"); // Clear the countdown end time
            return 0; // Reset countdown to 0
          }
          return newCountdown;
        });
      }, 1000);
    } else if (!countdownActive && countdown === 0) {
      // Elapsed time counting starts after countdown finishes
      elapsedInterval = setInterval(() => {
        setElapsedTime((prevTime) => {
          const newElapsedTime = prevTime + 1;
          localStorage.setItem("elapsedTime", newElapsedTime); // Save to local storage
          return newElapsedTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
      clearInterval(elapsedInterval);
    };
  }, [countdownActive, countdown]);

  // Handle sharedState changes while timer is active
  useEffect(() => {
    if (countdownActive) {
      // Stop the current countdown if it's running
      setCountdownActive(false);
    }

    // Set new countdown value based on the updated sharedState
    if (sharedState) {
      const newCountdownValue = sharedState * 60; // Convert to seconds
      setCountdown(newCountdownValue);
      localStorage.setItem("countdownEndTime", Date.now() + newCountdownValue * 1000); // Save new countdown end time to local storage
      setCountdownActive(true); // Start countdown
    }
  }, [sharedState]);

  // Format time function
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const displayTime = countdownActive ? countdown : elapsedTime;

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName || userName) {
      const decodedUserName = decodeURIComponent(storedUserName);
      setUserName(decodedUserName);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    onLogout();
  };

  const isAddDealPageArr = ["/AddDeal", "/DetailVerification"];
  const isAddDealPage = isAddDealPageArr.includes(window.location.pathname);

  const toggleFullScreen = () => {
    if (!fullScreen) {
      handle.enter();
    } else {
      handle.exit();
    }
    setfullScreen(!fullScreen);
  };

  const play = (audioPath) => {
    const audio = new Audio(audioPath);
    audio.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  useEffect(() => {
    const shared = localStorage.getItem("isShared") === "true";
    setIsShared(shared);
  }, []);
  const DealId = localStorage.getItem("DealId");
  const destinationUrl = !IsShared ? "/" : `/DetailVerification/${DealId}`;

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md py-0 w-100">
          <div className="container-fluid px-lg-20">
            <div className="left_column">
              <NavLink to={destinationUrl} aria-label="Read">
                <img src="/Images/Group34011.png" alt="" onClick={playClickSound} />
              </NavLink>

              <img src="/Images/Frame12.png" alt="" />
            </div>
            <div className="text-end">
              <button className="d-md-none border-0 px-2 py-1 RxHamburgerMenu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive" aria-controls="offcanvasResponsive">
                <RxHamburgerMenu />
              </button>
            </div>

            <div className="offcanvas-md offcanvas-end" id="offcanvasResponsive" aria-labelledby="offcanvasResponsiveLabel">
              <div className="offcanvas-header justify-content-end ">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <div className="Right_column Mobile-Sidebar-ele">
                  <div className="Mobile-profile">
                    {/* dynamic user profile images here */}
                    <img src="/Images/Group18.png" alt="" className="Profile_img" />
                    <span>{userName}</span>
                  </div>

                  <button className="FullScreen_Button" onClick={toggleFullScreen} aria-label="Fullscreen">
                    {fullScreen ? <RxExitFullScreen onClick={playClickSound} /> : <RxEnterFullScreen onClick={playClickSound} />}
                    <span className="Mobile-sidebar-option">Fullscreen</span>
                  </button>
                  {!shouldHideSpan && (
                    <div className="Clock_box" style={{ display: isAddDealPage ? "none" : "block" }}>
                      <div className="d-flex align-items-center CLock-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                          <g clipPath="url(#clip0_2394_613)">
                            <path d="M9 0.5C4.03725 0.5 0 4.53725 0 9.5C0 14.4628 4.03725 18.5 9 18.5C13.9628 18.5 18 14.4628 18 9.5C18 4.53725 13.9628 0.5 9 0.5ZM12 10.25H9C8.586 10.25 8.25 9.91475 8.25 9.5V5C8.25 4.58525 8.586 4.25 9 4.25C9.414 4.25 9.75 4.58525 9.75 5V8.75H12C12.4148 8.75 12.75 9.08525 12.75 9.5C12.75 9.91475 12.4148 10.25 12 10.25Z" fill="#2E302E" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2394_613">
                              <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>{formatTime(displayTime)}</span>
                      </div>
                    </div>
                  )}

                  <div className="d-flex align-items-center Lanuage-Inner">
                    <label className="form-check-label">ENG</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={playClickSound} aria-label="Search" />
                    </div>
                    <label className="form-check-label">ESP</label>
                  </div>
                  <div className="Line">|</div>

                  <a href="/" className="Setting_Link" data-bs-toggle="modal" data-bs-target="#SettingsModal" aria-label="Read">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" onClick={playClickSound}>
                      <g clipPath="url(#clip0_2394_624)">
                        <path
                          d="M21.0004 12C21.0002 11.4483 20.95 10.8977 20.8504 10.355L23.8934 8.6L20.8934 3.4L17.8494 5.159C17.009 4.43993 16.0432 3.88194 15.0004 3.513V0H9.00042V3.513C7.95768 3.88194 6.99187 4.43993 6.15142 5.159L3.10742 3.4L0.107422 8.6L3.15042 10.355C2.95053 11.4426 2.95053 12.5574 3.15042 13.645L0.107422 15.4L3.10742 20.6L6.15142 18.842C6.99195 19.5607 7.95775 20.1184 9.00042 20.487V24H15.0004V20.487C16.0432 20.1181 17.009 19.5601 17.8494 18.841L20.8934 20.6L23.8934 15.4L20.8504 13.645C20.95 13.1023 21.0002 12.5517 21.0004 12ZM15.0004 12C15.0004 12.5933 14.8245 13.1734 14.4948 13.6667C14.1652 14.1601 13.6967 14.5446 13.1485 14.7716C12.6003 14.9987 11.9971 15.0581 11.4152 14.9424C10.8332 14.8266 10.2987 14.5409 9.8791 14.1213C9.45954 13.7018 9.17382 13.1672 9.05807 12.5853C8.94231 12.0033 9.00172 11.4001 9.22878 10.8519C9.45585 10.3038 9.84036 9.83524 10.3337 9.50559C10.8271 9.17595 11.4071 9 12.0004 9C12.7961 9 13.5591 9.31607 14.1217 9.87868C14.6844 10.4413 15.0004 11.2044 15.0004 12Z"
                          fill="#2E302E"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2394_624">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="Mobile-sidebar-option">Settings</span>
                  </a>
                  <div className="Mobile-logout">
                    <NavLink className="dropdown-item logout" onClick={handleLogout}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
                        <g clipPath="url(#clip0_771_3280)">
                          <path
                            d="M15.476 19C15.2108 19 14.9564 19.1054 14.7689 19.2929C14.5814 19.4804 14.476 19.7348 14.476 20V23C14.476 23.7956 14.1599 24.5587 13.5973 25.1213C13.0347 25.6839 12.2717 26 11.476 26H9C8.20435 26 7.44129 25.6839 6.87868 25.1213C6.31607 24.5587 6 23.7956 6 23V9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H11.476C12.2717 6 13.0347 6.31607 13.5973 6.87868C14.1599 7.44129 14.476 8.20435 14.476 9V12C14.476 12.2652 14.5814 12.5196 14.7689 12.7071C14.9564 12.8946 15.2108 13 15.476 13C15.7412 13 15.9956 12.8946 16.1831 12.7071C16.3706 12.5196 16.476 12.2652 16.476 12V9C16.4744 7.67441 15.9471 6.40356 15.0098 5.46622C14.0724 4.52888 12.8016 4.00159 11.476 4H9C7.67441 4.00159 6.40356 4.52888 5.46622 5.46622C4.52888 6.40356 4.00159 7.67441 4 9L4 23C4.00159 24.3256 4.52888 25.5964 5.46622 26.5338C6.40356 27.4711 7.67441 27.9984 9 28H11.476C12.8016 27.9984 14.0724 27.4711 15.0098 26.5338C15.9471 25.5964 16.4744 24.3256 16.476 23V20C16.476 19.7348 16.3706 19.4804 16.1831 19.2929C15.9956 19.1054 15.7412 19 15.476 19Z"
                            fill="#E15454"
                          />
                          <path
                            d="M26.867 13.8786L22.281 9.29259C22.1888 9.19708 22.0784 9.1209 21.9564 9.06849C21.8344 9.01608 21.7032 8.9885 21.5704 8.98734C21.4376 8.98619 21.3059 9.01149 21.183 9.06177C21.0602 9.11205 20.9485 9.18631 20.8546 9.2802C20.7607 9.37409 20.6865 9.48574 20.6362 9.60864C20.5859 9.73154 20.5606 9.86321 20.5618 9.99599C20.5629 10.1288 20.5905 10.26 20.6429 10.382C20.6953 10.504 20.7715 10.6143 20.867 10.7066L25.129 14.9696L10 14.9996C9.73478 14.9996 9.48043 15.1049 9.29289 15.2925C9.10536 15.48 9 15.7344 9 15.9996C9 16.2648 9.10536 16.5192 9.29289 16.7067C9.48043 16.8942 9.73478 16.9996 10 16.9996L25.188 16.9686L20.865 21.2926C20.7695 21.3848 20.6933 21.4952 20.6409 21.6172C20.5885 21.7392 20.5609 21.8704 20.5598 22.0032C20.5586 22.136 20.5839 22.2676 20.6342 22.3905C20.6845 22.5134 20.7587 22.6251 20.8526 22.719C20.9465 22.8129 21.0582 22.8871 21.181 22.9374C21.3039 22.9877 21.4356 23.013 21.5684 23.0118C21.7012 23.0107 21.8324 22.9831 21.9544 22.9307C22.0764 22.8783 22.1868 22.8021 22.279 22.7066L26.865 18.1206C27.4277 17.5583 27.744 16.7955 27.7444 16C27.7447 15.2045 27.4292 14.4414 26.867 13.8786Z"
                            fill="#E15454"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_771_3280">
                            <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                          </clipPath>
                        </defs>
                      </svg>{" "}
                      <span>Logout</span>
                    </NavLink>
                  </div>

                  <div className="dropdown Logout_dropdown" onClick={playClickSound}>
                    {!IsShared ? (
                      <a herf="#" className="Profile_Link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="/Images/Group18.png" alt="" className="Profile_img" />
                        <div>
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </a>
                    ) : (
                      <div className="Profile_Link-IsShared">
                        <img src="/Images/Group18.png" alt="" className="Profile_img" />
                        <div className="Profile_Link-data">
                          <p>Your F&I </p>
                          <span>{userName}</span>
                        </div>
                      </div>
                    )}

                    <ul className="dropdown-menu">
                      <li>
                        <NavLink className="dropdown-item logout" onClick={handleLogout}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
                            <g clipPath="url(#clip0_771_3280)">
                              <path
                                d="M15.476 19C15.2108 19 14.9564 19.1054 14.7689 19.2929C14.5814 19.4804 14.476 19.7348 14.476 20V23C14.476 23.7956 14.1599 24.5587 13.5973 25.1213C13.0347 25.6839 12.2717 26 11.476 26H9C8.20435 26 7.44129 25.6839 6.87868 25.1213C6.31607 24.5587 6 23.7956 6 23V9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6H11.476C12.2717 6 13.0347 6.31607 13.5973 6.87868C14.1599 7.44129 14.476 8.20435 14.476 9V12C14.476 12.2652 14.5814 12.5196 14.7689 12.7071C14.9564 12.8946 15.2108 13 15.476 13C15.7412 13 15.9956 12.8946 16.1831 12.7071C16.3706 12.5196 16.476 12.2652 16.476 12V9C16.4744 7.67441 15.9471 6.40356 15.0098 5.46622C14.0724 4.52888 12.8016 4.00159 11.476 4H9C7.67441 4.00159 6.40356 4.52888 5.46622 5.46622C4.52888 6.40356 4.00159 7.67441 4 9L4 23C4.00159 24.3256 4.52888 25.5964 5.46622 26.5338C6.40356 27.4711 7.67441 27.9984 9 28H11.476C12.8016 27.9984 14.0724 27.4711 15.0098 26.5338C15.9471 25.5964 16.4744 24.3256 16.476 23V20C16.476 19.7348 16.3706 19.4804 16.1831 19.2929C15.9956 19.1054 15.7412 19 15.476 19Z"
                                fill="#E15454"
                              />
                              <path
                                d="M26.867 13.8786L22.281 9.29259C22.1888 9.19708 22.0784 9.1209 21.9564 9.06849C21.8344 9.01608 21.7032 8.9885 21.5704 8.98734C21.4376 8.98619 21.3059 9.01149 21.183 9.06177C21.0602 9.11205 20.9485 9.18631 20.8546 9.2802C20.7607 9.37409 20.6865 9.48574 20.6362 9.60864C20.5859 9.73154 20.5606 9.86321 20.5618 9.99599C20.5629 10.1288 20.5905 10.26 20.6429 10.382C20.6953 10.504 20.7715 10.6143 20.867 10.7066L25.129 14.9696L10 14.9996C9.73478 14.9996 9.48043 15.1049 9.29289 15.2925C9.10536 15.48 9 15.7344 9 15.9996C9 16.2648 9.10536 16.5192 9.29289 16.7067C9.48043 16.8942 9.73478 16.9996 10 16.9996L25.188 16.9686L20.865 21.2926C20.7695 21.3848 20.6933 21.4952 20.6409 21.6172C20.5885 21.7392 20.5609 21.8704 20.5598 22.0032C20.5586 22.136 20.5839 22.2676 20.6342 22.3905C20.6845 22.5134 20.7587 22.6251 20.8526 22.719C20.9465 22.8129 21.0582 22.8871 21.181 22.9374C21.3039 22.9877 21.4356 23.013 21.5684 23.0118C21.7012 23.0107 21.8324 22.9831 21.9544 22.9307C22.0764 22.8783 22.1868 22.8021 22.279 22.7066L26.865 18.1206C27.4277 17.5583 27.744 16.7955 27.7444 16C27.7447 15.2045 27.4292 14.4414 26.867 13.8786Z"
                                fill="#E15454"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_771_3280">
                                <rect width="24" height="24" fill="white" transform="translate(4 4)" />
                              </clipPath>
                            </defs>
                          </svg>{" "}
                          <span>Logout</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <Suspense>
        <NavbarModal />
      </Suspense>
    </>
  );
};

export default Navbar;
