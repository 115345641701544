import React, { useEffect, useState, forwardRef } from "react";

const SelectField = forwardRef(({ options, Selectlabel, className, label, required, disabled, value, onChange, InputName, productDetail, db_name}, ref) => {
  const [updatedOptions, setUpdatedOptions] = useState([]);

  useEffect(() => {
    if (options.length > 0) {
      setUpdatedOptions(options);
    }
  }, [options]);

  return (
    <div className={className}>
      <label className="form-label">{Selectlabel}</label>
      <select className="form-select" aria-label={label} name={InputName} value={value} onChange={onChange} disabled={disabled} required={required} option={updatedOptions} ref={ref} selected_option={db_name ? productDetail[db_name] : ""}>
        <option value="0" disabled></option>
        {updatedOptions.map((option, index) =>
          db_name && productDetail ? (
            productDetail[db_name] == option.label || productDetail[db_name] == option.value ? (
              <option key={index} selected value={option.value}>
                {option.label}
              </option>
            ) : (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            )
          ) : (
            <option key={index} value={option.value} >
              {option.label}
            </option>
          )
        )}
      </select>
    </div>
  );
});

export default SelectField;
