import React, { forwardRef } from "react";
import "../../Dashboard.css";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";
import { useAudio } from "../../../../AudioContext";

const Seviceoptions = forwardRef(({ ProductId, WarrantyImages, ServicesTitle, OptionMSG, onServiceClick, ondltproductClick, onRemoveProduct, HandletriviaTextVideo }, ref) => {
  const { playClickSound } = useAudio();

  const handleClick = () => {
    playClickSound();
    onServiceClick(ServicesTitle);
    ondltproductClick(ProductId);
    // setTimeout(() => {
    //   HandletriviaTextVideo(ProductId);
    // }, 4000);
  };

  const AddProduct = (event) => {
    playClickSound();
    event.stopPropagation();
    onRemoveProduct(ProductId);
  };

  return (
    <>
      <div ref={ref} className={`option-With-Text ${ProductId}`} onClick={handleClick}>
        <HiPlus className="Add-Product-Icon" onClick={AddProduct} />
        <NavLink data-bs-toggle="modal" data-bs-target="#TotalCareShieldAndComman">
          <div className="Option clickTarget">
            <img className="clickTarget" src={`/assets/Images/DashboardImages/${WarrantyImages}`} alt="" />
            <span className="clickTarget">{OptionMSG}</span>
          </div>
          <p>{ServicesTitle}</p>
        </NavLink>
      </div>
    </>
  );
});

export default Seviceoptions;
