import React, { useRef, useState, useEffect } from "react";
import "../../Dashboard.css";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import AccordionData from "./Accordion_Questions_Answers.json";
import Loader from "../../../CommonFolder/Loader";
import { useAudio } from "../../../../AudioContext";
const SearchData = ({ setActiveButton, activeButton, ActiveIndexAccordion, setActiveIndexAccordion, VideoLiveLink, isModalOpen, receivedSameVideo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [videoPlay, setvideoPlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const { playClickSound } = useAudio();

  const handleVideoLoadedData = () => {
    setLoading(false);
  };

  const handleVideoLoadStart = () => {
    setLoading(true);
  };
  const filteredVideos = AccordionData.filter((category) => category.title === activeButton).flatMap((category) => category.faqs);
  const activeVideo = filteredVideos[ActiveIndexAccordion];

  useEffect(() => {
    const allVideos = document.querySelectorAll("video");
    allVideos.forEach((video) => {
      if (video !== videoRef.current) {
        video.pause();
      }
    });

    if (videoRef.current && activeVideo) {
      videoRef.current.src = activeVideo.videoURL;
      videoRef.current.play();
      setIsPlaying(true);

      videoRef.current.addEventListener("ended", handleVideoEnded);
    }

    setPreviousIndex(ActiveIndexAccordion);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [activeButton, ActiveIndexAccordion]);

  const handleButtonClick = (title) => {
    setActiveButton(title);
    setActiveIndexAccordion(null);
    setvideoPlay(false);
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleVideoPlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
    setvideoPlay(true);
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
    setvideoPlay(true);
  };

  const handleClickAudio = () => {
    playClickSound();
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
    setvideoPlay(false);
  };

  if (isModalOpen) {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }

  useEffect(() => {
    const video = videoRef.current;

    // Check if the video reference is not null
    if (video) {
      // Check if the video is paused
      if (video.paused) {
        video.currentTime = 0; // Reset to start
        video
          .play()
          .then(() => {
            setIsPlaying(true); // Successfully playing
          })
          .catch((error) => {
            console.error("Error playing video:", error);
          });
      } else {
        // If the video is already playing, reset and start from the beginning
        video.currentTime = 0;
        video.play();
        setIsPlaying(true);
      }
    } else {
      console.error("Video element is not available");
    }
  }, [receivedSameVideo]);

  return (
    <>
      <div className="Search-AI-boat">
        {(ActiveIndexAccordion !== undefined && ActiveIndexAccordion !== null) || VideoLiveLink ? (
          <div className="Search_Versa_Bottons">
            {isPlaying ? (
              <button onClick={toggleVideoPlay}>
                <FaPause />
              </button>
            ) : (
              <button onClick={toggleVideoPlay}>
                <FaPlay />
              </button>
            )}
            <button onClick={replayVideo}>
              <MdOutlineReplay />
            </button>
          </div>
        ) : (
          <></>
        )}
        <div className="Video_Box Small-versa-bot-dashboard">
          {VideoLiveLink ? (
            <>
              <video ref={videoRef} autoPlay src={VideoLiveLink ? VideoLiveLink : undefined} style={{ display: "block" }} onClick={toggleVideoPlay} onLoadedData={handleVideoLoadedData} onLoadStart={handleVideoLoadStart} />
              {loading && <Loader />}
            </>
          ) : (
            AccordionData.map((category) =>
              category.faqs.map((faq, faqIndex) => {
                const isActiveVideo = activeButton === category.title && ActiveIndexAccordion === faqIndex;
                const videoSource = isActiveVideo && faq.videoURL;

                return (
                  <React.Fragment key={faqIndex}>
                    <video data-index={faqIndex} ref={isActiveVideo ? videoRef : null} autoPlay={isActiveVideo} src={videoSource ? videoSource : undefined} style={{ display: isActiveVideo ? "block" : "none" }} onClick={toggleVideoPlay} onLoadedData={isActiveVideo ? handleVideoLoadedData : null} onLoadStart={isActiveVideo ? handleVideoLoadStart : null} />
                    {isActiveVideo && loading && <Loader />}
                  </React.Fragment>
                );
              })
            )
          )}
          <img
            onClick={() => {
              if (VideoLiveLink || AccordionData.some((category) => category.faqs.some((faq, index) => activeButton === category.title && ActiveIndexAccordion === index))) {
                toggleVideoPlay();
              }
            }}
            src="/assets/Images/DashboardImages/newversaNew.jpg"
            alt="Default Image"
          />
        </div>
      </div>
      <div className="Search-Total-Data">
        {AccordionData.map((button, index) => (
          <button key={index} className={`SearchData ${activeButton === button.title ? "active" : ""}`} onClick={() => handleButtonClick(button.title)}>
            <span onClick={handleClickAudio}>{button.title}</span>
          </button>
        ))}
      </div>
    </>
  );
};

export default SearchData;
